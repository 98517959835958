import './Css/AccountSettingContainer.css';
import React, { useState, useEffect } from 'react';
import { AiFillEye, AiFillEyeInvisible, AiOutlineEdit } from 'react-icons/ai';

function AccountSettingContainer() {
  const [userName, setUserName] = useState("username");
  const [userPassword, setUserPassword] = useState("password");
  const [showPassword, setShowPassword] = useState(false);
  const [usernameFieldStatus, setUserNameFieldStatus] = useState(true);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = () => {
    setUserName("210201007");
    setUserPassword("95@15.280");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleUsernameEditability = () => {
    setUserNameFieldStatus(!usernameFieldStatus);
  };

  const saveUserHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Updated Username:", userName);
    console.log("Updated Password:", userPassword);
  };

  return (
    <div className="accountSettingContainer">
      <h1 className="accountSettingContainer-infoText">Hesap Bilgilerini Güncelle</h1>
      <form className="accountSettingContainer-accountForm" onSubmit={saveUserHandler}>
        <label>Kullanıcı Adı</label>
        <div className="accountSettingContainer-inputWrapper">
          <input
            type="text"
            name="userName"
            value={userName}
            readOnly={usernameFieldStatus}
            onChange={(e) => setUserName(e.target.value)} // Update userName when edited
          />
          <span onClick={toggleUsernameEditability} className="accountSettingContainer-icon">
            <AiOutlineEdit />
          </span>
        </div>

        <label>Şifre</label>
        <div className="accountSettingContainer-inputWrapper">
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            value={userPassword} // Use userPassword as the controlled value
            onChange={(e) => setUserPassword(e.target.value)} // Update userPassword on change
          />
          <span onClick={togglePasswordVisibility} className="accountSettingContainer-icon">
            {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
          </span>
        </div>

        <button type="submit" className="accountSettingContainer-submitButton">Güncelle</button>
      </form>
    </div>
  );
}

export default AccountSettingContainer;
